import axios from 'axios'

const stringifyPath = (path) => {
  if (Array.isArray(path)) {
    return path.join('/')
  }

  return path
}

export const axiosQueryClient = axios.create({
  headers: {
    Accept: 'application/json',
    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')?.content,
  },
})

axiosQueryClient.interceptors.request.use(
  function (config) {
    if (config.showProgressBar) {
      window.NProgress?.start()
    }
    return config
  },
  function (error) {
    window.NProgress?.done()
    return Promise.reject(error)
  }
)

axiosQueryClient.interceptors.response.use(
  function (response) {
    window.NProgress?.done()
    return response
  },
  function (error) {
    window.NProgress?.done()
    return Promise.reject(error)
  }
)

export function getFn(path, axiosConfig) {
  axiosConfig = axiosConfig || {}
  return async () =>
    (
      await axiosQueryClient.get(stringifyPath(path), {
        showProgressBar: false,
        ...axiosConfig,
      })
    ).data
}

export function postFn(path, data, axiosConfig) {
  axiosConfig = axiosConfig || {}
  return async () =>
    (
      await axiosQueryClient.post(stringifyPath(path), data, {
        showProgressBar: true,
        ...axiosConfig,
      })
    ).data
}
